import React from 'react'
import GoogleMapReact from 'google-map-react';
import dropoffPin from '../assets/dropoff-pin.png';
import pickupPin from '../assets/pickup-pin.png';
import motoPin from '../assets/vehicle-top-view/moto.png';
import obhaigPin from '../assets/vehicle-top-view/obhaig.png';
import cngPin from '../assets/vehicle-top-view/cng.png';
import { useMediaQuery } from '@material-ui/core';


const CurrentLocationMarker = ({ bearing, car_type }) => <div>
    {car_type === 'GARI' ? <img src={obhaigPin} style={{ transform: `rotate(${bearing}deg)` }} alt="marker" />
        : car_type === 'CNG' ? <img src={cngPin} style={{ transform: `rotate(${bearing}deg)` }} alt="marker" />
            : <img src={motoPin} style={{ transform: `rotate(${bearing}deg)` }} alt="marker" />
    }
</div>;
const PickupLocationMarker = () => <div><img src={pickupPin} alt="marker" /></div>;
const DropoffLocationMarker = () => <div><img src={dropoffPin} alt="marker" /></div>;

const Maps = ({ data }) => {

    // const largeScreens = useMediaQuery('(min-width:801px)');
    const phoneScreens = useMediaQuery('(max-width:800px)');

    const center = {
        lat: data.driver_location[0],
        lng: data.driver_location[1]
    };
    const zoom = 11;

    const mapStylesLargeScreen = {
        height: '100%',
        width: '100%',
        position: 'fixed',
        padding: 0,
        top: 0,
        zIndex: -1
    }
    const mapStylesPhoneScreen = {
        height: '40%',
        width: '100%',
        position: 'absolute',
        padding: 0,
        top: 0,
        // zIndex: -1
    }

    return (
        <div style={phoneScreens ? mapStylesPhoneScreen : mapStylesLargeScreen}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
                defaultCenter={center}
                defaultZoom={zoom}
            >
                <CurrentLocationMarker
                    lat={data.driver_location[0]}
                    lng={data.driver_location[1]}
                    bearing={data.driver_bearing}
                    car_type={data.driver_car_type}
                />
                <PickupLocationMarker
                    lat={data.pickup_location[0]}
                    lng={data.pickup_location[1]}
                />
                <DropoffLocationMarker
                    lat={data.dropoff_location[0]}
                    lng={data.dropoff_location[1]}
                />
            </GoogleMapReact>
        </div>
    )
}

export default Maps;
