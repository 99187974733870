import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import NotFound from './Pages/NotFound';
import Track from './Pages/Track';

function App() {
  return (
    <Router>
      <Switch>
      <Route path="/ride/:id" exact render={(props) => <Track {...props} />} />
        {/* <Route path="/ride/:id" component={Track} /> */}
        <Route path="/not-found" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
