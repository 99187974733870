import { Card, CardContent, Grid, IconButton, makeStyles, Snackbar, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RoomIcon from '@material-ui/icons/Room';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { CopyToClipboard } from "react-copy-to-clipboard";
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
    root: {
        width: '400px',
        top: '4%',
        right: '65%',
        left: '2%',
        background: '#000',
        position: 'absolute'
    },
    rootPhone: {
        maxWidth: '100%',
        height: '100%',
        top: '40%',
        right: 0,
        left: 0,
        background: '#000',
        position: 'fixed',
        overflow: 'scroll'
    },
    colorWhite: {
        color: '#fff'
    },
    padding: {
        padding: '0.75rem'
    },
    image: {
        width: '4rem',
        height: '4rem',
        /* fill the container, preserving aspect ratio, and cropping to fit */
        backgroundSize: 'cover',

        /* center the image vertically and horizontally */
        backgroundPosition: 'top center',

        /* round the edges to a circle with border radius 1/2 container size */
        borderRadius: '50%',
    },
    rating: {
        marginTop: '-0.75rem',
        maxWidth: '3.5rem',
        borderRadius: '20%'
    },
    vl: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '30px',
        paddingBottom: '30px',
        backgroundColor: '#fff',
        width: '0.2em',
        marginBottom: '3px',
    }
}));

const RideInfo = ({ data }) => {

    const classes = useStyles();
    const [isCopied, setIsCopied] = useState(false);
    const [open, setOpen] = React.useState(false);

    // MEDIA QUERIES
    const phoneScreens = useMediaQuery('(max-width:800px)');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const onCopyText = () => {
        setIsCopied(true);
        setOpen(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };

    return (
        <>
            <>
                {
                    phoneScreens ?
                        <div className={classes.rootPhone} style={{ borderBottom: 'none' }}>
                            <CardContent>
                                <Grid container className={classes.padding}>
                                    <Grid item xs={12}> {/* pickup address */}
                                        <Grid container>
                                            <Grid item xs={2} align="center">
                                                <FiberManualRecordIcon fontSize="small" className={classes.colorWhite} />
                                                <div className={classes.vl}></div>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="body2" className={classes.colorWhite}>{data.pickup_address}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}> {/* dropoff address */}
                                        <Grid container>
                                            <Grid item xs={2}
                                                align="center"><RoomIcon fontSize="small" className={classes.colorWhite} /></Grid>
                                            <Grid item xs={10}><Typography variant="body2" className={classes.colorWhite}>{data.dropoff_address}</Typography></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <div className={classes.padding} style={{ background: '#fff', height: '100%' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} align="center">
                                        <div style={{ backgroundImage: `url(${data.driver_image})` }} className={classes.image} />
                                        <Card className={classes.rating}>
                                            <Typography variant="subtitle2">★ {data.driver_rating.toFixed(2)}</Typography>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="body1" style={{ paddingBottom: '5' }}>{data.driver_car_type === 'GARI' ? 'OBHAI G™' : data.driver_car_type}</Typography>
                                        <Typography variant="body1"><b>{data.driver_name}</b></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card style={{ padding: 15 }}>
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <Typography variant="subtitle2">Contact Number</Typography>
                                                    <Typography variant="h6">{data.driver_phone}</Typography>
                                                </Grid>
                                                <Grid item xs={2} align="right">
                                                    <CopyToClipboard text={data.driver_phone} onCopy={onCopyText}>
                                                        <IconButton size="small" aria-label="close" color="inherit" style={{ marginTop: '1rem' }}>
                                                            <FileCopyOutlinedIcon />
                                                        </IconButton>
                                                    </CopyToClipboard>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        :
                        <Card className={classes.root}>
                            <CardContent>
                                <Grid container className={classes.padding}>
                                    <Grid item xs={12}> {/* pickup address */}
                                        <Grid container>
                                            <Grid item xs={2} align="center">
                                                <FiberManualRecordIcon fontSize="small" className={classes.colorWhite} />
                                                <div className={classes.vl}></div>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="body2" className={classes.colorWhite}>{data.pickup_address}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}> {/* dropoff address */}
                                        <Grid container>
                                            <Grid item xs={2}
                                                align="center"><RoomIcon fontSize="small" className={classes.colorWhite} /></Grid>
                                            <Grid item xs={10}><Typography variant="body2" className={classes.colorWhite}>{data.dropoff_address}</Typography></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent className={classes.padding} style={{ background: '#fff' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} align="center">
                                        <div style={{ backgroundImage: `url(${data.driver_image})` }} className={classes.image} />
                                        <Card className={classes.rating}>
                                            <Typography variant="subtitle2">★ {data.driver_rating.toFixed(2)}</Typography>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography variant="body1" style={{ paddingBottom: '5' }}>{data.driver_car_type === 'GARI' ? 'OBHAI G™' : data.driver_car_type}</Typography>
                                        <Typography variant="body1"><b>{data.driver_name}</b></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Card style={{ padding: 15 }}>
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <Typography variant="subtitle2">Contact Number</Typography>
                                                    <Typography variant="h6">{data.driver_phone}</Typography>
                                                </Grid>
                                                <Grid item xs={2} align="right">
                                                    <CopyToClipboard text={data.driver_phone} onCopy={onCopyText}>
                                                        <IconButton size="small" aria-label="close" color="inherit" style={{ marginTop: '1rem' }}>
                                                            <FileCopyOutlinedIcon />
                                                        </IconButton>
                                                    </CopyToClipboard>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                }
            </>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Copied to clipboard"
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </>

    )
}

export default RideInfo;
