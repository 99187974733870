import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import ObhaiLogo from '../assets/obhai-logo.png'

const NotFound = () => {

    return (
        <Container>
            <Grid container style={{ marginTop: '3rem' }} spacing={4}>
                <Grid item xs={12} align="center">
                    <img src={ObhaiLogo} alt="company_logo" width="150" />
                </Grid>
                <Grid item xs={12} align="center">
                    <Typography variant="h4">
                        No Ride Information Available
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default NotFound;