import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Maps from '../Components/Maps';
import { useHistory } from 'react-router';
import RideInfo from '../Components/RideInfo';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Track = (props) => {
    const [data, setData] = useState({});
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);
    const { id } = props.match.params;
    // const largeScreens = useMediaQuery('(min-width:801px)');
    const phoneScreens = useMediaQuery('(max-width:800px)');

    useEffect(() => {

        const func = () => {

            Axios.post(process.env.REACT_APP_BASE_URL,
                {
                    "engagement_id": id
                }
            ).then(res => {

                if (res.data.status === 200) {
                    setData(res.data.data);
                    setLoaded(true);
                } else {
                    clearInterval(intervalFunc);
                    history.push('/not-found');
                }

            }, error => {
                clearInterval(intervalFunc);
                history.push('/not-found');
            })
        };

        func();
        const intervalFunc = setInterval(func, 20000);

    }, []);

    return (
        <>
            {
                loaded &&
                <>
                    {
                        phoneScreens ?
                            <>
                                <Maps
                                    data={data}
                                />
                                <RideInfo
                                    data={data}
                                />
                            </>
                            :
                            <>
                                <RideInfo
                                    data={data}
                                />
                                <Maps
                                    data={data}
                                />
                            </>
                    }

                </>
            }
        </>
    )
};

export default Track;